import React from 'react';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
// import Faq from 'components/Faq';
import ArchivePostSingle from 'components/ArchivePostSingle';
import Meta from 'components/Meta';
import SupportHeader from 'components/SupportHeader';
import Scrollspy from 'components/Scrollspy';
// import theme from 'styles/theme';
// import Main from 'styles/supportInner';
// import { BlogListSupport } from 'styles/support';
import SupportInnerNav from '../../components/SupportInnerNav/SupportInnerNav';
import { signUp } from '../../utils/contentProvider/function';
import 'styles/supportInner.scss';
const SupportUsingruttl = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
}) => (
  <Layout hideHeader location={location}>
    {({ toggleContactUs, toggleSignup }) => (
      <>
        <Meta
          title="Support documentation 🔨 | ruttl"
          description="ruttl simplifies website reviews with easy commenting and live editing. Invite stakeholders for precise, contextual feedback on pixel-perfect designs."
          url="https://ruttl.com/support/"
        />
        <main className="supportinner-styed-main">
          <SupportHeader toggleContactUs={toggleContactUs} />
          <section className="supportinner-hero">
            <div className="container">
              <h1>Using ruttl:</h1>
              <p>
                Check everything right from creating a project, to moving
                elements and replacing images.
              </p>
            </div>
          </section>
          <SupportInnerNav />
          <section className="support-inner-block">
            <Scrollspy>
              <div className="flex-1 support-inner-content">
                <img src="/assets/img/support-get-started.png" alt="" />

                <h2
                  data-spy-title="Creating your first project in ruttl"
                  data-spy-id="creating-your-first-project-in-ruttl">
                  Creating your first project in ruttl
                </h2>

                <p>
                  To create your first project, simply&nbsp;
                  <button type="button" onClick={() => signUp(toggleSignup)}>
                    signup
                  </button>
                  &nbsp;or&nbsp;
                  <a
                    href="https://web.ruttl.com/"
                    target="_blank"
                    rel="nofollow noopener noreferrer">
                    login
                  </a>
                  &nbsp;to ruttl and click on ‘Create Project’. Once you give it
                  a name, you will be able to create the project.
                </p>

                <h3
                  data-spy-title="Adding your first webpage to ruttl"
                  data-spy-id="adding-your-first-webpage-to-ruttl">
                  Adding your first webpage to ruttl
                </h3>

                <p>
                  For webpages, once your project is created, please copy the
                  URL of the webpage you would like to add. Paste it inside the
                  project and click on ‘Add page’. The webpage will be added to
                  your project, and will be ready for review. For static images,
                  please click on ‘Upload Image’, select the image from your
                  system and upload it. This image will be added to Ruttl and
                  will be ready for review.
                </p>

                <h3
                  data-spy-title="Uploading a PDF or image to your project"
                  data-spy-id="uploading-a-pdf-or-image-to-your-project">
                  Uploading a PDF or image to your project:
                </h3>

                <p>
                  To upload a PDF, simply head to a project and click on the
                  ‘Upload file’ button. Select the file(s), upload them &amp;
                  you will be ready to review them with your collaborators.
                </p>

                <h3
                  data-spy-title="How to upload a screenshot of your webpage?"
                  data-spy-id="how-to-upload-a-screenshot-of-your-webpage">
                  How to upload a screenshot of your webpage?
                </h3>

                <p>
                  In the rare event that your webpage UI doesn’t load properly
                  on ruttl, we have a backup - upload a screenshot of your
                  webpage to ruttl. Simply add the URL and select ‘Capture
                  screenshot’ from the dropdown next to it. After a few seconds,
                  your webpage’s screenshot will be added to your ruttl project.
                </p>

                <h3
                  data-spy-title="Sharing your project with other people"
                  data-spy-id="sharing-your-project-with-other-people">
                  Sharing your project with other people
                </h3>
                <p>There are 2 methods in which you can share your project:</p>
                <ol type="1">
                  <li>
                    Share to an email ID with View, Comment or Edit access -
                    Open the project which you would like to share. Click on the
                    ‘Share’ button on the top right side, copy and paste the
                    email ID of the user with whom you would like to share this
                    project, and send an invite. The person will receive an
                    email, with the help of which he/she will be able to access
                    the project in his/her available access.
                  </li>
                  <li>
                    Share the project link - This is especially useful for
                    sharing a project with the clients, since clients want to
                    see progress on the project without logging into any
                    platform. Inside any project, go to the top right hand
                    corner, click on ‘Share’. On the next pop-up, toggle the
                    ‘Shareability’ to on, copy the project shareable link and
                    share this with your client. The client will then be able to
                    see all the comments, replies to comments and edits on all
                    the webpages of the project.
                  </li>
                </ol>

                <h3
                  data-spy-title="Commenting on live websites"
                  data-spy-id="Commenting-on-live-websites">
                  Commenting on live websites
                </h3>

                <p>
                  To comment on a live website, create a project, add the
                  webpage URL and click on ‘View page’. The webpage will load in
                  ‘Comment mode’ by default, you can confirm the same in the
                  bottom bar. Hover over any element and click on it, a comment
                  box will appear. Type your comment here, and once it is done,
                  click on ‘Comment’ or press Enter/Return key. Your comment is
                  now added to a live website on ruttl. Keep adding as many
                  comments as required on this webpage. You can also add more
                  teammates and tag them in comments using the ‘@’ sign while
                  commenting.
                </p>

                <h3
                  data-spy-title="Moving elements on live websites"
                  data-spy-id="moving-elements-on-live-websites">
                  Moving elements on live websites
                </h3>
                <p>
                  To move elements on live websites, open the webpage inside
                  ruttl and go to ‘Edit mode’. Search the element which you
                  would like to move around, i.e. any image, content, icon, or
                  any other section and click on it. Now, use your keyboard
                  arrow keys to move the element around as per your requirement.
                  Once done, click on ‘Save changes’ and then your element is
                  moved. You can check the dimensions of your movement in the
                  ‘Activities Panel’ to the right hand side of the screen.
                </p>

                <h3
                  data-spy-title="Editing content on live websites"
                  data-spy-id="rditing-content-on-live-websites">
                  Editing content on live websites
                </h3>
                <p>
                  Inside the project, open the webpage where you want to make
                  the content edits. On the bottom bar, go to ‘Edit mode’ and
                  click on the content section where you would like to make
                  content changes, and then click on the ‘Edit text’ button.
                  Enter the content as required, click outside the content box
                  and click on ‘Save changes’. Your content will be saved on
                  ruttl. You can also make multiple changes together and then
                  save the changes at once.
                </p>

                <h3
                  data-spy-title="Replacing images on live websites"
                  data-spy-id="replacing-images-on-live-websites">
                  Replacing images on live websites
                </h3>

                <p>
                  To replace any image on your website, you need to be in
                  ruttl’s ‘Edit mode’. There are 2 methods to replace images:
                </p>
                <ol type="1">
                  <li>
                    Replace from Unsplash - Click on the image that you would
                    like to replace. You will see a small button to the top left
                    corner of the image named ‘Replace image’. Select ‘Photo
                    Library’ and a list of images from Unsplash will appear.
                    Search for your required image, select it, adjust the size
                    and then click on ‘Save changes’. Your image will now be
                    replaced. Your developer, or anyone else with Edit access to
                    this project can download the image from the ‘Activities
                    panel’ and use it on the live website.
                  </li>
                  <li>
                    Replace from your computer - Click on the image that you
                    would like to replace. You will see a small button to the
                    top left corner of the image named ‘Replace image’. Select
                    ‘Upload Image’, a small pop-up will appear, from which you
                    can select the image from your computer which you want to
                    place on the webpage. Once selected, click on ‘Save
                    Changes’. Your developer, or anyone else with Edit access to
                    this project can download the image from the ‘Activities
                    panel’ and use it on the live website.
                  </li>
                </ol>
                <h3
                  data-spy-title="Using the Activities Panel"
                  data-spy-id="using-the-Activities-Panel">
                  Using the Activities Panel
                </h3>
                <p>
                  After you make any changes in ‘Edit mode’ on the webpage, your
                  changes are reflected in the ‘Activities panel’. The
                  activities panel can be seen on the right hand side of the
                  screen in Comment and Edit modes. In comment mode, all
                  comments, replies to comments, resolved comments, and the
                  ability to resolve, edit or delete a comment can be seen in
                  the activities panel itself. In edit mode, all the changes to
                  font, font properties, button properties, image replacements,
                  and more can be seen in the Activities panel itself.
                </p>
                <h3 data-spy-title="What’s New" data-spy-id="whats-new">
                  What’s New
                </h3>
                <p>
                  Stay updated on all the latest features and improvements
                  released on Ruttl. On your main dashboard, click on the
                  question-mark icon (Need Help section), click on ‘What’s New’
                  and you shall be able to check all the updates on Ruttl.
                </p>
                <h3
                  data-spy-title="Mark a webpage or image as approved"
                  data-spy-id="mark-a-webpage-or-image-as-approved">
                  Mark a webpage or image as approved
                </h3>
                <p>
                  Approving a webpage or image will ensure that no more comments
                  or edits can be made. To do so, the project owner will have to
                  select an ‘Approver’ by going to the ‘Share project’ modal.
                  Here, clicking on any project collaborator’s profile picture
                  will make them an approver for that project. The project owner
                  can also mark themselves as an approver. This approver can
                  open any webpage or image, and on the bottom bar, click on
                  ‘Mark Approved’. No more comments or edits will be allowed on
                  these approved webpages or images, unless they’re marked as
                  unapproved again. Process of unapproving also takes place by
                  an approver at the same place (bottom bar) inside any webpage
                  or image.
                </p>
                <h3
                  data-spy-title="Dashboard filters"
                  data-spy-id="dashboard-filters">
                  Dashboard filters
                </h3>
                <p>
                  Customise the view on your dashboard to only see the projects
                  added by you, or by another specific user. To do so, on your
                  main dashboard, click on ‘All Projects’ and select any user.
                  You shall then be able to view only the projects created by
                  that specific user. To only see your own projects, toggle the
                  switch of ‘Created by me’.
                </p>
                <h3
                  data-spy-title="Project-inside filters"
                  data-spy-id="project-inside-filters">
                  Project-inside filters
                </h3>
                <p>
                  Select what would you like to view inside your project. Choose
                  between web pages or images, and ruttl will only show you
                  those respectively. You can also toggle the switch of ‘Show
                  approved only’ to only see the webpages or images that are
                  approved.
                </p>
              </div>
            </Scrollspy>
          </section>
          <main className="blog-list-support">
            <section
              className="bg-lightGrey"
              style={{
                // backgroundColor: theme.colors.lightGrey,
                padding: '10px 0',
              }}>
              <div className="container">
                <div className="other-articles-block">
                  <h3>Popular Articles</h3>
                  <ul>
                    {allPosts.map(({ node }) => (
                      <li key={node.id}>
                        <ArchivePostSingle
                          link={`/blog/${node.slug}/`}
                          title={node.title}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </main>
          {/* <Faq id="faq" /> */}
        </main>
      </>
    )}
  </Layout>
);

SupportUsingruttl.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SupportUsingruttl;

export const pageQuery = graphql`
  query SupportUsingruttlQuery {
    allContentfulBlogPost(
      filter: { knowledgeBox: { eq: false } }
      limit: 10
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          knowledgeBox
          category {
            name
          }
        }
      }
    }
  }
`;
